import React from 'react';
import { graphql } from 'gatsby';
import { Craft_PageBuilderBlogUnion, Maybe } from '~/query';
import CraftComponents from '../CraftComponents';
import { IsBlogContext } from './IsBlogContext';

/**
 * Available components.
 *
 * Defines available first-level components within
 * the page builder. Don't forget to add it
 * to the query as well.
 */
const SegmentBlog = require('~/components/craft/CraftSegment/CraftSegmentBlog')
  .default;
// const NarrowSegment = (props: React.ComponentProps<typeof Segment>) =>
//   React.createElement(Segment, props);
// NarrowSegment.defaultProps = { defaultWidth: 'narrow', noPadding: true };

const availableComponents = {
  Segment: SegmentBlog,
};

type PageBuilderBlogProps = {
  components?: Maybe<Craft_PageBuilderBlogUnion>[] | null;
};

/**
 * Component:
 * Page builder.
 *
 * Renders predefined components dynamically.
 */
export default function PageBuilderBlog({ components }: PageBuilderBlogProps) {
  return (
    <IsBlogContext.Provider value>
      <CraftComponents
        components={components}
        availableComponents={availableComponents}
        prefix="Craft_PageBuilderBlog"
      />
    </IsBlogContext.Provider>
  );
}

/**
 * Fragment:
 * Page builder.
 *
 * The page builder query includes all queries
 * for the given available components.
 */
export const query = graphql`
  fragment CraftPageBuilderBlogQuery on Craft_PageBuilderBlogUnion {
    __typename
    ...CraftSegmentBlogQuery
  }
`;
