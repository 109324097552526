import React from 'react';
import { graphql } from 'gatsby';
import {
  Query,
  Craft_Blog,
  Craft_BlogArticlesArticle,
  Craft_BlogTagsTags,
} from '~/query';
import Seo from '~/components/common/Seo';
import BlogTags from '~/components/common/BlogTags';
import BlogRelated from '~/components/common/BlogRelated';
import BlogArticleHeader from '~/components/common/BlogArticleHeader';
import CraftPageBuilderBlog from '~/components/craft/CraftPageBuilderBlog';

type TemplateBlogArticlesProps = {
  data: Query;
};

/**
 * Template: BlogArticles
 *
 * Use this template in correspondence with a craft
 * section with the following properties:
 *
 * Type: channel
 * Name: blogArticles
 * URI: {parent.uri}/{slug}
 *
 * And an entry type with the following properties:
 * Name: article
 */
export default function TemplateBlogArticles({
  data,
}: TemplateBlogArticlesProps) {
  const craft = (data?.craft ?? undefined) as any;
  const page = (craft.post ?? {}) as Craft_BlogArticlesArticle;
  const blog = (craft.blog ?? {}) as Craft_Blog;

  const { title, seoTitle, seoDescription, seoCanonical, postDate } = page;

  const blogHeaderImageLayout = page.blogHeaderImageLayout ?? 'headerImage';
  const pageHeaderImage =
    (page.pageHeaderImage && page.pageHeaderImage[0]) ?? undefined;

  const blogUri = blog?.fullUri ?? ('' as string);

  const tags = (page?.blogTags ?? []) as Array<Craft_BlogTagsTags>;
  const moreArticles = (craft.moreArticles ?? {}) as Array<
    Craft_BlogArticlesArticle
  >;

  return (
    <>
      <Seo
        title={seoTitle ?? title}
        description={seoDescription ?? undefined}
        canonical={seoCanonical ?? undefined}
      />

      <BlogArticleHeader
        article={page}
        blog={blog}
        date={postDate}
        image={pageHeaderImage}
        imageLayout={blogHeaderImageLayout}
      />

      {page.pageBuilderBlog && (
        <CraftPageBuilderBlog components={page.pageBuilderBlog} />
      )}

      {tags && <BlogTags tags={tags} blogUri={blogUri} />}

      {moreArticles && (
        <BlogRelated moreArticles={moreArticles} blogUri={blogUri} />
      )}
    </>
  );
}

export const query = graphql`
  query QueryArticle($id: [Int]!, $site: String!) {
    craft {
      blog: entry(uri: "blog", site: $site) {
        fullUri
      }
      post: entry(id: $id, site: $site) {
        id
        title
        ... on Craft_BlogArticlesArticle {
          blogHeaderImageLayout
          postDate
          pageHeaderImage {
            ...CraftImageFluid
          }
          pageBuilderBlog {
            ...CraftPageBuilderBlogQuery
          }
          blogCategories {
            title
            slug
            id
          }
          blogTags {
            title
            slug
            id
          }
          # SEO
          seoTitle
          seoDescription
          seoCanonical
        }
      }
      moreArticles: entries(site: $site, type: BlogArticlesArticle, limit: 2) {
        id
        title
        fullUri
        ... on Craft_BlogArticlesArticle {
          postDate
          blogArticleTeaserImage {
            ...CraftImageFluid
          }
          blogCategories {
            title
            slug
            id
          }
        }
      }
    }
  }
`;
