import React from 'react';
import { Craft_Blog, Craft_BlogArticlesArticle } from '~/query';
import { Box, Flex } from 'rebass';
import Icon from '~/components/common/Icon';
import Headline from '~/components/common/Headline';
import PageHeaderSpacing from '~/components/common/PageHeaderSpacing';
import PageHeader from '~/components/common/PageHeader';
import Segment from '~/components/common/Segment';
import BlockContent from '~/components/common/BlockContent';
import CraftGatsbyImage from '~/components/craft/CraftGatsbyImage';
import BlogDate from '~/components/common/BlogDate';

import { CategoryLink } from './BlogArticleHeader.styles';
import { IsBlogContext } from '~/components/craft/CraftPageBuilderBlog/IsBlogContext';

export default function BlogArticleHeader({
  article,
  blog,
  postDate,
  imageLayout,
  image,
}: any) {
  const blogUri = blog?.fullUri ?? ('' as string);
  const category = (article?.blogCategories?.[0]?.title ?? undefined) as String;
  const categoryLink = (`${blogUri}/category/${article?.blogCategories?.[0]?.slug}` ??
    '') as string;

  return (
    <IsBlogContext.Provider value={true}>
      {imageLayout == 'headerImage' ? (
        <PageHeader
          backgroundImage={image ?? undefined}
          bigSpaceBottom
          overlay={false}
        />
      ) : (
        <PageHeaderSpacing />
      )}

      <Segment width="narrow" noPadding>
        <Box pt={3}>
          {imageLayout == 'contentImage' && (
            <CraftGatsbyImage
              fluid={image}
              args={{
                width: image.width,
              }}
            />
          )}
        </Box>
      </Segment>

      {category || postDate ? (
        <Segment width="narrow">
          <Box py={3}>
            <Flex justifyContent="space-between" pt={2}>
              {category && (
                <CategoryLink to={categoryLink}>
                  <Icon name={'Globe'} />
                  <span>{category}</span>
                </CategoryLink>
              )}

              {postDate && <BlogDate date={postDate} />}
            </Flex>
          </Box>
        </Segment>
      ) : null}

      <Segment width="narrow" noPadding>
        <BlockContent>
          <Box px={{ _: '30px', md: 4, lg: 5, xl: 6 }}>
            <Headline level="h1" tag="div" style={{ margin: 0 }}>
              {article.title}
            </Headline>
          </Box>
        </BlockContent>
      </Segment>
    </IsBlogContext.Provider>
  );
}

BlogArticleHeader.defaultProps = {
  article: null,
  blog: '',
  imageLayout: 'headerImage',
  image: undefined,
};

type BlogArticleHeader = {
  article: Craft_BlogArticlesArticle;
  blog: Craft_Blog;
  imageLayout: string;
  image: any;
};
