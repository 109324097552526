/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Box, Flex } from 'rebass';
import Button from '../Button';
import ThemeProvider from '../ThemeProvider';

type BlockBannerProps = {
  children: React.ReactNode;
  hasChildren: boolean;
  themeName?: string;
  buttonSize?: 'normal' | 'large';
  link: {
    to?: string;
    target?: string;
    text?: string;
  };
};

export default function BlockBanner({
  children,
  hasChildren,
  themeName,
  buttonSize,
  link,
}: BlockBannerProps) {
  return (
    <ThemeProvider name={themeName}>
      <Box mx={-4} my={2}>
        <Box px={4} bg="background" color="text">
          <Flex
            mx={-2}
            py={1}
            alignItems="center"
            justifyContent="stretch"
            flexWrap="wrap"
          >
            {hasChildren ? (
              <Box
                px={2}
                py={1}
                flex="10 1 50%"
                sx={{
                  'p:first-of-type': { marginTop: 0 },
                  'p:last-of-type': { marginBottom: 0 },
                }}
              >
                {children}
              </Box>
            ) : null}
            <Box
              px={2}
              py={1}
              sx={{
                flexShrink: 0,
                flexGrow: 1,
                textAlign: hasChildren ? 'left' : 'center',
              }}
              height="100%"
            >
              <Button
                to={link.to}
                target={link.target}
                variant={buttonSize === 'large' ? 'filledLarge' : 'filled'}
                mobileWidth="100%"
              >
                {link.text}
              </Button>
            </Box>
          </Flex>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
