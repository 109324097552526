import React from 'react';
import { Craft_BlogArticlesArticle } from '~/query';
import { Flex, Box } from 'rebass';
import Button from '~/components/common/Button';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import CraftGatsbyImage from '~/components/craft/CraftGatsbyImage';
import BlogDate from '~/components/common/BlogDate';
import Headline from '~/components/common/Headline';
import Link from '~/components/common/Link';
import Icon from '~/components/common/Icon';
import Segment from '../Segment';

import {
    BlogTeaserHeadline,
    BlogTeaserLink,
    BlogTeaserMeta,
    BlogTeaserCategory,
    BlogCategoryLink
} from './BlogRelated.styles'

export default function BlogRelated({moreArticles, blogUri}: any) {
    const t = useCraftTranslations();

    return (
        <Segment width="narrow" >
            <Box py={{_: 3, md: 4}}>
                <Headline level="h2">{t("More posts")}</Headline>
                <Flex flexWrap="wrap"  mx={{_: 0, md: -1}} >
                    {
                        moreArticles.map((props: any) => {
                            const {title, fullUri, id, postDate, blogArticleTeaserImage, blogCategories} = props;
                            const category = (blogCategories?.[0]?.title ?? undefined) as String;
                            const categoryLink = (`${blogUri}/category/${blogCategories?.[0]?.slug}` ?? '') as string;

                            return (
                                <Box width={{_: 1, md: 1/2}} mb={{_: 2, md: 0}} px={{_: 0, md: 1}} key={id}>

                                    {blogArticleTeaserImage && (
                                        <Link to={fullUri}>
                                            <CraftGatsbyImage fluid={blogArticleTeaserImage[0]} />
                                        </Link>
                                    )}

                                    {(postDate || category) && (
                                        <BlogTeaserMeta py={1}>
                                            {postDate && (
                                                <BlogDate date={postDate} />
                                            )}

                                            {category && (
                                                <BlogCategoryLink to={categoryLink}>
                                                    <BlogTeaserCategory>
                                                            <Icon name={"Globe"} /><span>{category}</span>
                                                    </BlogTeaserCategory>
                                                </BlogCategoryLink>
                                            )}
                                        </BlogTeaserMeta>
                                    )}

                                    <BlogTeaserLink to={fullUri}>
                                        <BlogTeaserHeadline level="h4">{title}</BlogTeaserHeadline>
                                    </BlogTeaserLink>
                                </Box>
                            )
                        })
                    }
                </Flex>
                <Box pt={3} pb={5}>
                    <Button
                        to={blogUri}
                        variant="primary"
                        iconName="none"
                        width="100%"
                        paddingSize="big"
                    >Zurück zu allen Beiträgen</Button>
                </Box>
            </Box>
        </Segment>
    );
}

BlogRelated.defaultProps = {
    moreArticles: null,
    blogUri: ''
};

type BlogRelated = {
    moreArticles: Craft_BlogArticlesArticle;
    blogUri: string;
};
