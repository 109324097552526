import React from 'react';
import { graphql } from 'gatsby';

import CraftComponents from '~/components/craft/CraftComponents';
import Segment from '~/components/common/Segment';
import { Craft_PageBuilderSegment } from '~/query';

/**
 * Available components.
 *
 * Defines available components
 * within this component.
 */
const availableComponents = {
  BlockContent: require('~/components/craft/CraftBlockContent').default,
  BannerBlock: require('~/components/craft/CraftBlockBanner').default,
};

type CraftSegmentBlogProps = {
  data: Craft_PageBuilderSegment;
  noPadding?: boolean;
};

export default function CraftSegmentBlog({ data, noPadding }: CraftSegmentBlogProps) {
  const { children } = data;

  return (
    <Segment
      themeName={undefined}
      alignItems={undefined}
      backgroundImage={undefined}
      height={undefined}
      borderBottom={undefined}
      width="narrow"
      noPadding={noPadding ?? undefined}
    >
      <CraftComponents
        components={children}
        availableComponents={availableComponents}
        prefix={/Craft_PageBuilder(Blog)?/}
      />
    </Segment>
  );
}

export const query = graphql`
  fragment CraftSegmentBlogQuery on Craft_PageBuilderBlogSegment {
    id
    # Content
    children {
      __typename

      # Blocks
      ...CraftPageBuilderBlogBlockContentQuery
      ...CraftPageBuilderBlogBannerBlockQuery
    }
  }
`;
