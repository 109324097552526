import styled from '~/utils/styled';
import Link from '~/components/common/Link';

export const CategoryLink = styled(Link)(({ theme }) => ({
  fontSize: 15,
  color: theme.colors.secondaryBright,
  textDecoration: 'none',
  textTransform: 'uppercase',
  display: 'inline-flex',
  alignItems: 'center',
  marginRight: 10,
  fontWeight: 600,

  svg: {
    marginRight: 5,
  },
}));
