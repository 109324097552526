import styled from '~/utils/styled';
import { Flex } from 'rebass';
import Link from '~/components/common/Link';
import Headline from '~/components/common/Headline';
import media from '~/utils/mediaqueries';

export const BlogTeaserLink = styled(Link)({
  textDecoration: 'none',
});

export const BlogTeaserHeadline = styled(Headline)({
  textTransform: 'none',
  marginTop: 0,

  [media('sm')]: {
    marginTop: 0,
  },
  [media('md')]: {
    marginTop: 0,
  },
  [media('lg')]: {
    marginTop: 0,
  },
});

export const BlogCategoryLink = styled(Link)({
  textDecoration: 'none',
});

export const BlogTeaserCategory = styled('span')(({ theme }) => ({
  marginLeft: 30,
  display: 'inline-flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: theme.colors.dim,

  div: {
    marginRight: 3,
  },
}));

export const BlogTeaserMeta = styled(Flex)(({ theme }) => ({
  alignItems: 'center',
  justifyItems: 'center',
  fontSize: 11,
  lineHeight: '11px',
  color: theme.colors.dim,
  fontWeight: 600,
}));
