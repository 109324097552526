import React from 'react';
import { graphql } from 'gatsby';

import CraftComponents from '~/components/craft/CraftComponents';
import BlockBanner from '~/components/common/BlockBanner';
import { Craft_PageBuilderBlogBannerBlock } from '~/query';

/**
 * Available components.
 *
 * Defines available components
 * within this component.
 */
const availableComponents = {
  ElementHeadline: require('~/components/craft/CraftElementHeadline').default,
  ElementText: require('~/components/craft/CraftElementText').default,
  // ElementImage: require('~/components/craft/CraftElementImage').default,
  // ElementButtonGroup: require('~/components/craft/CraftElementButtonGroup')
  //   .default,
  // ElementContactInfo: require('~/components/craft/CraftElementContactInfo')
  //   .default,
  // ElementIconList: require('~/components/craft/CraftElementIconList').default,
  // ElementDownloads: require('~/components/craft/CraftElementDownloads').default,
  // ElementFormContact: require('~/components/craft/CraftElementFormContact')
  //   .default,
  // ElementVideo: require('~/components/craft/CraftElementVideo').default,
  // ElementGallery: require('~/components/craft/CraftElementGallery').default,
  // ElementAnchor: require('~/components/craft/CraftElementAnchor').default,
  // ElementDiscount: require('~/components/craft/CraftElementDiscount').default,
  // ElementList: require('~/components/craft/CraftElementList').default,
};

type CraftBlockBannerProps = {
  data: Craft_PageBuilderBlogBannerBlock;
};

export default function CraftBlockBanner({ data }: CraftBlockBannerProps) {
  const { children, segmentThemeName, bannerLink, bannerButtonSize } = data;

  return (
    <BlockBanner
      themeName={segmentThemeName || undefined}
      buttonSize={bannerButtonSize || undefined}
      link={{
        to: bannerLink?.entry?.fullUri || bannerLink?.url || undefined,
        target: bannerLink?.target || undefined,
        text: bannerLink?.customText || bannerLink?.text || undefined
      }}
      hasChildren={Boolean(children && children. length)}
    >
      <CraftComponents
        components={children}
        availableComponents={availableComponents}
        prefix={/Craft_PageBuilder(Blog)?/}
      />
    </BlockBanner>
  );
}

export const query = graphql`
  fragment CraftPageBuilderBlogBannerBlockQuery on Craft_PageBuilderBlogBannerBlock {
    id
    # Content
    children {
      __typename
      # Content: Elements
      ...CraftPageBuilderBlogElementHeadlineQuery
      ...CraftPageBuilderBlogElementTextQuery
      #...CraftPageBuilderBlogElementImageQuery
      #...CraftPageBuilderBlogElementGalleryQuery
      #...CraftPageBuilderBlogElementVideoQuery
    }
    segmentThemeName
    bannerLink {
      entry {
        fullUri
      }
      url
      target
      text
      customText
    }
    bannerButtonSize
  }
`;
