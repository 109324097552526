import React from 'react';
import { Craft_BlogTagsTags } from '~/query';
import { Box } from 'rebass';
import Segment from '~/components/common/Segment';

import {
  TagSectionCaption,
  TagLink,
  TagsWrapper,
  TagsContainer,
  Tags,
} from './BlogTags.styles';

export default function BlogTags({ tags, blogUri }: any) {
  return tags && tags.length ? (
    <Segment width={'narrow'} backgroundColor="tertiaryBright">
      <Box py={3} px={[0, 0, 2]}>
        <TagsWrapper>
          <TagSectionCaption>Tags</TagSectionCaption>
          <TagsContainer>
            <Tags>
              {tags.map(({ id, slug }: any) => {
                const tagLink = (`${blogUri}/tag/${slug}` ?? '') as string;
                return (
                  <TagLink to={tagLink} key={id}>
                    {slug}
                  </TagLink>
                );
              })}
            </Tags>
          </TagsContainer>
        </TagsWrapper>
      </Box>
    </Segment>
  ) : null;
}

BlogTags.defaultProps = {
  tags: null,
  blogUri: '',
};

type BlogTags = {
  tags: Craft_BlogTagsTags;
  blogUri: string;
};
