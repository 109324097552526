import { Box } from 'rebass';
import styled from '~/utils/styled';
import media from '~/utils/mediaqueries';
import Link from '~/components/common/Link';

export const Tags = styled(Box)({
  display: 'inline',

  [media('sm')]: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

export const TagsWrapper = styled(Box)({
  display: 'block',

  [media('sm')]: {
    display: 'flex',
  },
});

export const TagsContainer = styled(Box)({
  display: 'inline',

  [media('sm')]: {
    display: 'block',
  },
});

export const TagSectionCaption = styled('span')(({ theme }) => ({
  marginTop: '0 !important',
  fontWeight: 100,
  color: theme.colors.text,
  padding: '10px 15px',
  display: 'inline',

  [media('sm')]: {
    display: 'block',
    padding: '20px 10px',
  },
}));

export const TagLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.colors.secondaryBright,
  textTransform: 'uppercase',
  fontWeight: 700,
  padding: '10px 15px',
  whiteSpace: 'nowrap',
  display: 'inline-block',

  [media('sm')]: {
    padding: '20px 30px',
  },
}));
